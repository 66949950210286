<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- 搜索 -->
    <el-form ref="form" inline :model="searchData" label-width="">
      <el-form-item label="组局类型名称">
        <el-input style="width: 200px" clearable v-model="searchData.name" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="线上/线下类型">
        <el-select style="width: 200px" v-model="searchData.type" placeholder="请选择" size="mini" clearable>
          <el-option label="线上游戏组局" :value="1"> </el-option>
          <el-option label="线下活动组局" :value="0"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 新增 -->
    <el-button
      style="margin-bottom: 10px"
      type="primary"
      icon="el-icon-plus"
      size="mini"
      plain
      @click="$router.push({ name: 'GroupTypeAdd' })"
      >新增</el-button
    >

    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="name" label="组局类型名称" min-width="160" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="typeName" label="类型" min-width="120">
        <template slot-scope="{ row }">
          <el-tag :type="row.type == 1 ? 'primary' : 'success'" size="mini">{{ row.typeName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="{ row }">
          <el-link
            style="margin-right: 10px"
            type="primary"
            :underline="false"
            @click="$router.push({ name: 'GroupTypeEdit', params: { id: row.id } })"
            >编辑</el-link
          >
          <el-link type="primary" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
  </div>
</template>

<script>
import { getListAPI, delAPI, getDetailAPI, addAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        name: '',
        type: ''
      },
      total: 0
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    // 列表
    async getList() {
      const res = await getListAPI(this.searchData)
      this.total = res.totalRow
      this.list = res.records
    },

    // 删除

    handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
